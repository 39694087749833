import "./styles.scss";
import React from "react";

import Button from "components/Button";

import BgImage from "images/info-page-bg.png";

const mainClass = "info-page";

const InfoPage = ({ title, text }) => {
  return (
    <div className={mainClass}>
      <div className="container container--medium">
        <div className={`${mainClass}__info`}>
          <h1>{title}</h1>
          <h4>{text}</h4>
        </div>
        <Button variant="white" href="/">
          Powrót do strony głównej
        </Button>
        <img src={BgImage} alt="" />
      </div>
    </div>
  );
};

export default InfoPage;
